<template>
  <!-- eslint-disable max-len -->
  <transition
    @leave="leave"
  >
    <Loading
      v-if="!load"
      :overflow="false"
    ></Loading>
  </transition>

  <!-- Main content -->
  <HomeCityBar
  :mode="sizeMode"
  :side-open="citiesOpened"
  ></HomeCityBar>
  <div
    class="nav-sidebar position-lg-absolute end-0
    d-flex flex-column align-items-end"
  >
    <nav class="menu-sidebar">
      <div class="bg-primary text-white">
        <h1
          class="text-uppercase text-start text-lg-center px-3 px-lg-0 mb-0"
          :class="citiesOpened ? 'text-secondary' : ''"
        >
          CITYA
        </h1>
      </div>
      <!-- Exhibition date desktop position -->
      <div class="landing-dates bg-primary d-none d-lg-block text-white p-3">
        <div class="h3 text-uppercase mb-0">sep 17 - nov 16 2021</div>
        <div
        class="h4 text-uppercase mb-0 mt-2">
          opening sep 17 gmt 00:00am
        </div>
      </div>
      <!-- Exhibition date -->

      <!-- Cities sidebar -->
      <!-- Toggler for mobile -->
      <div
        class="bg-primary text-white p-3 d-lg-none"
      >
        <a
          class="nav-sidebar-toggler cursor-pointer text-uppercase text-white
          text-decoration-none d-flex justify-content-between"
          @click.prevent="toggleCitiesCollapse"
        >
          <span class=""
            :class="citiesOpened ? 'text-secondary' : ''"
          >Cities</span>
          <i
            class="material-icons md-36 nav-sidebar-toggler-icon"
            :class="citiesOpened ? 'rotate text-secondary' : ''"
            >add</i
          >
        </a>
      </div>
      <!-- End of Toggler for mobile -->
      <div
        class="bg-primary text-white p-0 p-lg-3"
        :class="{
          'nav-open show' : citiesOpened,
          'position-absolute hide-top w-100' : sizeMode !== 'desktop'
        }"
      >
        <a
          class="nav-sidebar-toggler cursor-pointer text-uppercase text-white
          text-decoration-none d-flex justify-content-between
          p-3 p-lg-0"
          @click.prevent="toggleCitiesCollapse"
        >
          <span class=""
            :class="citiesOpened ? 'text-secondary' : ''"
          >Cities</span>
          <i
            class="material-icons md-36 nav-sidebar-toggler-icon"
            :class="citiesOpened ? 'rotate text-secondary' : ''"
            >add</i
          >
        </a>
        <!-- Cities collapsed content -->
        <div class="collapse mt-0 mt-md-3" ref="CitiesCollapse">
          <router-link
            v-for="(school, index) in schools"
            class="h3 text-uppercase text-white text-decoration-none
            mb-0 w-100"
            :key="school.city"
            :to="school.link"
          >
            <span
              class="px-3 px-lg-0"
            >
              {{ school.city }}
            </span>
            <!-- <InfinityText :school="school.name"></InfinityText> -->
            <div
              class="sliding-holder position-relative overflow-hidden
              mb-2 mb-md-4 mb-lg-0 mt-2 mt-lg-0"
            >
              <span
                :ref="'text' + index"
                class="h5 position-absolute"
              >
                {{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              <span
                :ref="'text' + index + '-clone'"
                class="h5 position-absolute"
              >
                {{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;{{ school.name }}&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </div>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
  <canvas id="homeThree" class="position-absolute top-0 z-index-bottom" ref="homeThree"> </canvas>
  <TimeZoneFooter
    :timezone='timezone'
    :mode="sizeMode"
    :side-open="citiesOpened"
  ></TimeZoneFooter>
</template>

<script>
import * as THREE from 'three';
import gsap from 'gsap';
import Collapse from 'bootstrap/js/dist/collapse';
import Loading from '@/components/front/LoadingGeneral.vue';
import HomeCityBar from '@/components/front/HomeCityBar.vue';
import TimeZoneFooter from '@/components/front/TimeZoneFooter.vue';

export default {
  name: 'Home',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      textureLoadStatus: false,
      cityLoadStatus: false,
      timeLoadStatus: false,
      targetTime: null,
      currentTime: null,
      // for ThreeJS canvas
      matcapTexture: null,
      scene: null,
      sceneBg: {
        hk: {
          day: '#004ADD',
          night: '#000E2A',
        },
        mo: {
          day: '#07EFEF',
          night: '#0D1C1C',
        },
        bj: {
          day: '#6600CC',
          night: '#1C0037',
        },
        sf: {
          day: '#48EF20',
          night: '#072300',
        },
        tll: {
          day: '#F93822',
          night: '#230400',
        },
        rm: {
          day: '#FFCD00',
          night: '#39331C',
        },
        citya: {
          day: '#F7F5F5',
          night: '#242020',
        },
      },
      canvas: '',
      renderer: null,
      camera: null,
      sizes: {},
      sizeMode: '',
      sphereMeshes: [
        // sphere 1
        {
          desktop: {
            size: 0.5,
            x: -2.7,
            y: -0.5,
            z: -1,
          },
          tablet: {
            size: 0.3,
            x: -1.6,
            y: -0.3,
            z: -1,
          },
          mobile: {
            size: 0.2,
            x: -1.2,
            y: -0.3,
            z: -1,
          },
        },
        // sphere 2
        {
          desktop: {
            size: 0.5,
            x: -1,
            y: -0.3,
            z: 0.3,
          },
          tablet: {
            size: 0.3,
            x: -0.6,
            y: -0.1,
            z: 0.3,
          },
          mobile: {
            size: 0.2,
            x: -0.5,
            y: -0.1,
            z: 0.3,
          },
        },
        // sphere 3
        {
          desktop: {
            size: 0.5,
            x: -0.1,
            y: 0.7,
          },
          tablet: {
            size: 0.3,
            x: -0.1,
            y: 0.4,
          },
          mobile: {
            size: 0.2,
            x: -0.1,
            y: 0.3,
          },
        },
        // sphere 4
        {
          desktop: {
            size: 0.5,
            x: 1.1,
            y: -1.1,
            z: -0.5,
          },
          tablet: {
            size: 0.3,
            x: 0.3,
            y: -0.7,
            z: -0.5,
          },
          mobile: {
            size: 0.2,
            x: 0.3,
            y: -0.6,
            z: -0.5,
          },
        },
        // sphere 5
        {
          desktop: {
            size: 0.5,
            x: 1.5,
            y: 0.1,
          },
          tablet: {
            size: 0.3,
            x: 0.8,
            y: -0.1,
          },
          mobile: {
            size: 0.2,
            x: 0.6,
            y: -0.2,
          },
        },
        // sphere 6
        {
          desktop: {
            size: 0.5,
            x: 2,
            y: -0.8,
            z: 0.5,
          },
          tablet: {
            size: 0.3,
            x: 1.2,
            y: -0.5,
            z: 0.5,
          },
          mobile: {
            size: 0.2,
            x: 0.8,
            y: -0.5,
            z: 0.5,
          },
        },
      ],
      cursor: {
        x: 0,
        y: 0,
      },
      requestId: '',
      // for SideNav
      citiesCollapse: '',
      citiesOpened: false,
      schools: [
        {
          city: 'hong kong',
          name: 'Academy of Visual Arts, Hong Kong Baptist University, Hong Kong',
          link: '/city/hong-kong',
        },
        {
          city: 'macao',
          name: 'Centre for Arts and Design, University of Macau, Macao',
          link: '/city/macao',
        },
        {
          city: 'Beijing',
          name: 'Virtual Lab for Art Museum, School of Arts Administration and Education, Central Academy of Fine Arts, Beijing',
          link: '/city/beijing',
        },
        {
          city: 'San Francisco',
          name: 'Art and Design Department, University of California, Berkeley Extension, San Francisco',
          link: '/city/san-francisco',
        },
        {
          city: 'tallinn',
          name: 'Estonian Academy of Arts, Tallinn',
          link: '/city/tallinn',
        },
        {
          city: 'rome',
          name: 'Fine Arts Academy of Rome, Rome',
          link: '/city/rome',
        },
      ],
      // for SideNav sliding text
      textWidth: [],
      startPos: [],
      // for Footer
      clock: null,
      currentCountry: 'citya',
      timezone: [
        {
          utc: 8,
          city: 'HK',
          country: 'Hong Kong',
        },
        {
          utc: 8,
          city: 'MO',
          country: 'Macau',
        },
        {
          utc: 8,
          city: 'BJ',
          country: 'China',
        },
        {
          utc: -7,
          city: 'SF',
          country: 'United States',
        },
        {
          utc: 3,
          city: 'TLL',
          country: 'Estonia',
        },
        {
          utc: 2,
          city: 'RM',
          country: 'Italy',
        },
        {
          utc: 0,
          city: 'CITYA',
          country: 'CITYA',
        },
      ],
      convertedHours: [],
    };
  },
  components: {
    Loading,
    HomeCityBar,
    TimeZoneFooter,
  },
  computed: {
    load() {
      return this.textureLoadStatus && this.cityLoadStatus && this.timeLoadStatus;
    },
  },
  watch: {
    /**
     * Watch loading status
     */
    matcapTexture() {
      if (this.matcapTexture) {
        this.textureLoadStatus = true;
      }
    },
    currentCountry() {
      console.log(this.currentCountry);
    },
    sizes: {
      deep: true,
      handler() {
        this.updatesizeMode();
        // console.log(this.sizeMode);
      },
    },
  },
  methods: {
    /**
     * Page Load effects
     */
    leave(el, done) {
      gsap.to(el, {
        duration: 0.3,
        delay: 1.8,
        opacity: 0,
        ease: 'ease-out',
        onComplete: done,
      });
    },
    /**
     * Main methods
     */
    getTime() {
      const today = new Date();
      const h = today.getUTCHours();
      this.convertHourByTimeZone(h);
      this.timeLoadStatus = true;
    },
    convertHourByTimeZone(h) {
      for (let i = 0; i < 7; i += 1) {
        let hour = h + this.timezone[i].utc;
        if (hour >= 24) {
          hour -= 24;
        } else if (hour < 0) {
          hour += 24;
        }
        this.convertedHours[i] = hour;
      }
    },
    updateCursorPosition(event) {
      this.cursor.x = event.clientX / this.sizes.width - 0.5;
      this.cursor.y = event.clientY / this.sizes.height - 0.5;
    },
    updateWindowSize() {
      this.sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
    updatesizeMode() {
      if (this.sizes.width <= 575.99) {
        this.sizeMode = 'mobile';
      } else if (this.sizes.width <= 991.99) {
        this.sizeMode = 'tablet';
      } else {
        this.sizeMode = 'desktop';
      }
    },
    initPosition() {
      // init start position for six cities
      this.startPos = [];
      for (let i = 0; i < 6; i += 1) {
        const posObj = {
          start: 0,
          target1: 0,
          target2: 0,
          cloneStart: 0,
        };
        this.startPos.push(posObj);
        this.textWidth[i] = this.$refs[`text${i}`].getBoundingClientRect().width;
        this.startPos[i].target1 = -this.textWidth[i];
        this.startPos[i].target2 = this.textWidth[i];
        this.startPos[i].cloneStart = this.textWidth[i];
        this.$refs[`text${i}-clone`].style.left = this.formatPos(this.textWidth);
      }
    },
    formatPos(pos) {
      return `${pos}px`;
    },
    infinitySliding() {
      for (let i = 0; i < 6; i += 1) {
        if (this.startPos[i].start <= this.startPos[i].target1) {
          this.startPos[i].start = this.startPos[i].target2;
        } else {
          this.startPos[i].start -= 1;
        }
        if (this.startPos[i].cloneStart <= this.startPos[i].target1) {
          this.startPos[i].cloneStart = this.startPos[i].target2;
        } else {
          this.startPos[i].cloneStart -= 1;
        }
        gsap.set(this.$refs[`text${i}`], { left: this.formatPos(this.startPos[i].start) });
        gsap.set(this.$refs[`text${i}-clone`], { left: this.formatPos(this.startPos[i].cloneStart) });
      }
    },
    initThree() {
      this.loadTexture();
      const scene = new THREE.Scene();
      this.$http
        .get(`https://extreme-ip-lookup.com/json/?key=${process.env.VUE_APP_IP_API_KEY}`)
        .then((res) => {
          // console.log(res);
          this.getTime();
          this.cityLoadStatus = true;
          switch (res.data.country) {
            case 'Hong Kong':
              if (this.convertedHours[0] > 6 && this.convertedHours[0] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.hk.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.hk.night}`);
              }
              break;
            case 'Macau':
              if (this.convertedHours[1] > 6 && this.convertedHours[1] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.mo.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.mo.night}`);
              }
              break;
            case 'China':
              if (this.convertedHours[2] > 6 && this.convertedHours[2] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.bj.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.bj.night}`);
              }
              break;
            case 'United States':
              if (this.convertedHours[3] > 6 && this.convertedHours[3] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.sf.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.sf.night}`);
              }
              break;
            case 'Estonia':
              if (this.convertedHours[4] > 6 && this.convertedHours[4] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.tll.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.tll.night}`);
              }
              break;
            case 'Italy':
              if (this.convertedHours[5] > 6 && this.convertedHours[5] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.rm.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.rm.night}`);
              }
              break;
            default:
              if (this.convertedHours[6] > 6 && this.convertedHours[6] < 18) {
                scene.background = new THREE.Color(`${this.sceneBg.citya.day}`);
              } else {
                scene.background = new THREE.Color(`${this.sceneBg.citya.night}`);
              }
              break;
          }
        })
        .catch((data, status) => {
          console.log(data, status);
        });
      this.canvas = this.$refs.homeThree;

      /*
       ** Sizes
       */
      this.sizes = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      this.updatesizeMode();

      /*
       ** Materal
       */
      const material = new THREE.MeshMatcapMaterial();
      material.matcap = this.matcapTexture;

      /*
       ** Geometries
       */
      const sphere1 = new THREE.Mesh(new THREE.SphereGeometry(
        this.sphereMeshes[0][this.sizeMode].size,
        64,
        64,
      ), material);
      sphere1.position.x = this.sphereMeshes[0][this.sizeMode].x;
      sphere1.position.y = this.sphereMeshes[0][this.sizeMode].y;
      sphere1.position.z = this.sphereMeshes[0][this.sizeMode].z;
      sphere1.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(sphere1.geometry.attributes.uv.array, 2),
      );
      const sphere2 = new THREE.Mesh(new THREE.SphereGeometry(
        this.sphereMeshes[3][this.sizeMode].size,
        64,
        64,
      ), material);
      sphere2.position.x = this.sphereMeshes[1][this.sizeMode].x;
      sphere2.position.y = this.sphereMeshes[1][this.sizeMode].y;
      sphere2.position.z = this.sphereMeshes[1][this.sizeMode].z;
      sphere2.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(sphere1.geometry.attributes.uv.array, 2),
      );
      const sphere3 = new THREE.Mesh(new THREE.SphereGeometry(
        this.sphereMeshes[2][this.sizeMode].size,
        64,
        64,
      ), material);
      sphere3.position.x = this.sphereMeshes[2][this.sizeMode].x;
      sphere3.position.y = this.sphereMeshes[2][this.sizeMode].y;
      sphere3.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(sphere1.geometry.attributes.uv.array, 2),
      );
      const sphere4 = new THREE.Mesh(new THREE.SphereGeometry(
        this.sphereMeshes[3][this.sizeMode].size,
        64,
        64,
      ), material);
      sphere4.position.x = this.sphereMeshes[3][this.sizeMode].x;
      sphere4.position.y = this.sphereMeshes[3][this.sizeMode].y;
      sphere4.position.z = this.sphereMeshes[3][this.sizeMode].z;
      sphere4.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(sphere1.geometry.attributes.uv.array, 2),
      );
      const sphere5 = new THREE.Mesh(new THREE.SphereGeometry(
        this.sphereMeshes[4][this.sizeMode].size,
        64,
        64,
      ), material);
      sphere5.position.x = this.sphereMeshes[4][this.sizeMode].x;
      sphere5.position.y = this.sphereMeshes[4][this.sizeMode].y;
      sphere5.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(sphere1.geometry.attributes.uv.array, 2),
      );
      const sphere6 = new THREE.Mesh(new THREE.SphereGeometry(
        this.sphereMeshes[5][this.sizeMode].size,
        64,
        64,
      ), material);
      sphere6.position.x = this.sphereMeshes[5][this.sizeMode].x;
      sphere6.position.y = this.sphereMeshes[5][this.sizeMode].y;
      sphere6.position.z = this.sphereMeshes[5][this.sizeMode].z;
      sphere6.geometry.setAttribute(
        'uv2',
        new THREE.BufferAttribute(sphere1.geometry.attributes.uv.array, 2),
      );
      scene.add(sphere1, sphere2, sphere3, sphere4, sphere5, sphere6);

      /*
       ** Camera
       */
      const camera = new THREE.PerspectiveCamera(
        50,
        this.sizes.width / this.sizes.height,
        0.1,
        100,
      );
      camera.position.z = 5;

      /*
       ** Renderer
       */
      const renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true });
      renderer.setSize(this.sizes.width, this.sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      /*
       ** Controls
       */
      // const controls = new OrbitControls(camera, renderer.domElement);
      // controls.enableDamping = true;

      /*
       ** Resize event
       */
      const resizeRendererToDisplaySize = () => {
        // Update sizes
        this.sizes.width = window.innerWidth;
        this.sizes.height = window.innerHeight;
        const canvasPixelWidth = this.canvas.width / window.devicePixelRatio;
        const canvasPixelHeight = this.canvas.height / window.devicePixelRatio;

        const needResize = canvasPixelWidth !== this.sizes.width
        || canvasPixelHeight !== this.sizes.height;
        if (needResize) {
          renderer.setSize(this.sizes.width, this.sizes.height);
          renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        }
        return needResize;
      };

      /**
       * Resize mesh event
       */
      // const resizeObject = () => {
      //   if (this.sizes.width <= 575.99) {
      //     console.log('resize mesh');
      //   }
      // };

      /**
       * Animate
       */
      const clock = new THREE.Clock();
      // console.log(clock);
      const animate = () => {
        /**
         * Three JS canvas
         */
        const elapsedTime = clock.getElapsedTime();

        sphere1.position.y += Math.sin(elapsedTime) * 0.001;
        sphere2.position.y += Math.sin(elapsedTime) * 0.001;
        sphere3.position.y += Math.sin(elapsedTime) * 0.001;
        sphere4.position.y += Math.sin(elapsedTime) * 0.001;
        sphere5.position.y += Math.sin(elapsedTime) * 0.001;
        sphere6.position.y += Math.sin(elapsedTime) * 0.001;

        // Update camera
        camera.position.x = Math.sin(elapsedTime) * 0.5;
        camera.position.y = Math.cos(elapsedTime) * 0.5;
        // camera.position.x = this.cursor.x * 3;
        // camera.position.y = this.cursor.y * 3;
        // console.log(this.sizes.width, this.sizes.height);
        const centerPos = new THREE.Vector3(0, 0, 0);
        camera.lookAt(centerPos);

        // Update controls
        // controls.update();

        renderer.render(scene, camera);

        /**
         * Infinity sliding text
         */
        if (this.citiesOpened) {
          this.infinitySliding();
        }
        this.requestId = requestAnimationFrame(animate);
        // requestAnimationFrame(animate);

        if (resizeRendererToDisplaySize()) {
          this.updateWindowSize();
          // console.log(this.sizes);
          camera.aspect = this.sizes.width / this.sizes.height;
          camera.updateProjectionMatrix();
          // resizeObject();
        }
      };
      animate();
    },
    loadTexture() {
      const textureLoader = new THREE.TextureLoader();
      this.matcapTexture = textureLoader.load(`${this.publicPath}textures/matcaps/11.png`);
    },
    stopRendering() {
      window.cancelAnimationFrame(this.requestId);
    },
    country(data) {
      this.currentCountry = data;
    },
    toggleCitiesCollapse() {
      this.citiesCollapse.toggle();
      this.initPosition();
      this.citiesOpened = !this.citiesOpened;
    },
  },
  mounted() {
    // console.clear();
    this.targetTime = new Date(process.env.VUE_APP_LAUNCH_DATE).getTime();
    this.currentTime = new Date().getTime();
    if ((this.targetTime - this.currentTime) > 0) {
      this.$router.push('/coming-soon');
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('overflow-hidden');
      this.citiesCollapse = new Collapse(this.$refs.CitiesCollapse, {
        toggle: false,
      });
      this.initPosition();
      this.initThree();
      window.addEventListener('mousemove', this.updateCursorPosition);
    }
  },
  unmounted() {
    if ((this.targetTime - this.currentTime) > 0) {
      this.$router.push('/coming-soon');
    } else {
      window.removeEventListener('mousemove', this.updateCursorPosition);
      this.stopRendering();
      for (let i = 0; i < 6; i += 1) {
        gsap.killTweensOf(this.$refs[`text${i}`]);
        gsap.killTweensOf(this.$refs[`text${i}-clone`]);
      }
    }
  },
};
</script>

<style lang="scss">
@import '../../assets/all';
</style>
