<template>
  <div class="position-absolute row g-0 p-0 bottom-0 text-white
  w-100">
    <div
      class="col-12 col-lg-3 bg-primary py-2 px-3 p-lg-3 d-flex align-items-center"
    >
      <h2
        class="text-uppercase mb-0"
        :class="displayForward ? 'typing-text' : ''">
        {{ displayString }}
      </h2>
    </div>

    <!-- Exhibition date mobile position -->
    <div
    class="col-12 d-block d-lg-none bg-primary py-2 px-3 p-lg-3"
    :class="sideOpen ? 'text-primary' : ''"
    >
      <div class="h3 text-uppercase mb-0">sep 17 - nov 16 2021</div>
      <div
      class="h4 text-uppercase mb-0 mt-2">
        opening sep 17 gmt 00:00am
      </div>
    </div>
    <!-- Exhibition date -->

    <!-- Timezone Desktop Static -->
    <div class="col-12 col-lg-12 d-none d-lg-block">
      <div class="d-flex align-items-center bg-primary w-100 p-3">
        <span
          class="me-3"
          v-for="(time, index) in timezone"
          :key="time.city"
        >
          <span
            class="d-flex align-items-center"
            :class="currentCountry === time.country ? 'text-white' : 'text-secondary'"
          >
            {{ convertedHours[index] }} : {{ minutes }} {{ time.city }}
            <i
              class="material-icons md-8 ms-2"
              :class="currentCountry === time.country ? 'd-block' : 'd-none'"
            >
              circle
            </i>
          </span>
        </span>
      </div>
    </div>
    <!-- Timezone Desktop Static -->

    <!-- Timezone Mobile Sliding -->
    <div class="col-12 d-block d-lg-none">
      <div class="sliding-holder-citybar bg-primary
      position-relative w-100 overflow-hidden
      d-flex d-lg-none align-items-center"
      >
        <span
          ref="timezoneSlide"
          class="en position-absolute text-uppercase mb-0
          d-flex align-items-center"
        >
          <template
            v-for="(time, index) in timezone"
            :key="time.city"
          >
            <span
              class="d-flex align-items-center me-3"
              :class="currentCountry === time.country ? 'text-white' : 'text-secondary'"
            >
              {{ convertedHours[index] }} : {{ minutes }} {{ time.city }}
              <i
                class="material-icons md-8 ms-2"
                :class="currentCountry === time.country ? 'd-block' : 'd-none'"
              >
                circle
              </i>
            </span>
          </template>
        </span>
        <span
          ref="timezoneSlide-clone"
          class="en position-absolute text-uppercase mb-0
          d-flex align-items-center"
        >
          <template
            v-for="(time, index) in timezone"
            :key="time.city"
          >
            <span
              class="d-flex align-items-center me-3"
              :class="currentCountry === time.country ? 'text-white' : 'text-secondary'"
            >
              {{ convertedHours[index] }} : {{ minutes }} {{ time.city }}
              <i
                class="material-icons md-8 ms-2"
                :class="currentCountry === time.country ? 'd-block' : 'd-none'"
              >
                circle
              </i>
            </span>
          </template>
        </span>
      </div>
    </div>
    <!-- Timezone Mobile Sliding -->
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  props: ['mode', 'timezone', 'side-open'],
  data() {
    return {
      strings: ['city as a medium'],
      typingTimeOut: null,
      counter: 0,
      iterationCounter: 0,
      displayCounter: 0,
      displayString: '',
      displayForward: true,
      timeout: 100,
      // Time
      getTimeTimeOut: null,
      convertedHours: [],
      minutes: 10,
      seconds: 10,
      currentCountry: '',
      // Sliding effect
      textWidth: [],
      startPos: [
        {
          start: 0,
          target1: 0,
          target2: 0,
          cloneStart: 0,
        },
      ],
    };
  },
  watch: {
    currentCountry() {
      if (this.currentCountry !== '' && this.mode !== '' && this.mode !== 'desktop') {
        setTimeout(() => {
          this.initPosition();
          this.loopRender();
        }, 500);
      }
    },
  },
  methods: {
    displayText() {
      const textLength = this.strings[this.counter].length;
      this.typingTimeOut = setTimeout(() => {
        this.displayString = this.strings[this.counter].substring(0, this.displayCounter);
        if (this.displayForward) {
          this.displayCounter += 1;
          if (this.displayCounter <= textLength) {
            this.displayText();
          } else {
            this.iterationCounter += 1;
            this.displayForward = false;
            this.displayText();
          }
        } else {
          // REVERSE TYPING
          this.displayCounter -= 1;
          if (this.displayCounter >= 0) {
            this.displayText();
          } else {
            this.iterationCounter += 1;
            this.displayForward = true;
            this.displayText();
          }
        }
      }, this.timeout);
    },
    getCountry() {
      this.$http
        .get('https://extreme-ip-lookup.com/json/')
        .then((res) => {
          this.currentCountry = res.data.country;
        })
        .catch((data, status) => {
          console.log(data, status);
        });
    },
    getTime() {
      this.getTimeTimeOut = setTimeout(() => {
        const today = new Date();
        const h = today.getUTCHours();
        this.minutes = today.getUTCMinutes();
        this.seconds = today.getUTCSeconds();
        this.convertHourByTimeZone(h);
        this.fixLayout();
        this.getTime();
      }, 500);
    },
    convertHourByTimeZone(h) {
      for (let i = 0; i < 7; i += 1) {
        let hour = h + this.timezone[i].utc;
        if (hour >= 24) {
          hour -= 24;
        } else if (hour < 0) {
          hour += 24;
        }
        if (hour < 10) {
          hour = `0${hour}`;
        }
        this.convertedHours[i] = hour;
      }
    },
    fixLayout() {
      // add zero in front of numbers < 10
      if (this.minutes < 10) {
        this.minutes = `0${this.minutes}`;
      }
      if (this.seconds < 10) {
        this.seconds = `0${this.seconds}`;
      }
      // return num;
    },
    /**
     * Sliding text animation
     */
    initPosition() {
      this.textWidth[0] = this.$refs.timezoneSlide.getBoundingClientRect().width;
      this.startPos[0].target1 = -this.textWidth[0];
      [this.startPos[0].target2] = this.textWidth;
      this.startPos[0].cloneStart = this.textWidth;
      this.$refs['timezoneSlide-clone'].style.left = this.formatPos(this.textWidth);
    },
    // start the render loop animation (rAF)
    loopRender() {
      if (!this.requestId) {
        this.requestId = requestAnimationFrame(() => {
          this.render();
        });
      }
    },
    // stop the render loop animation (rAF)
    stopRendering() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
      }
    },
    formatPos(pos) {
      return `${pos}px`;
    },
    render() {
      this.requestId = undefined;
      if (this.startPos[0].start <= this.startPos[0].target1) {
        this.startPos[0].start = this.startPos[0].target2;
      } else {
        this.startPos[0].start -= 1;
      }
      if (this.startPos[0].cloneStart <= this.startPos[0].target1) {
        this.startPos[0].cloneStart = this.startPos[0].target2;
      } else {
        this.startPos[0].cloneStart -= 1;
      }
      gsap.set(this.$refs.timezoneSlide, { left: this.formatPos(this.startPos[0].start) });
      gsap.set(this.$refs['timezoneSlide-clone'], { left: this.formatPos(this.startPos[0].cloneStart) });
      this.loopRender();
    },
  },
  mounted() {
    this.getCountry();
    this.getTime();
    setTimeout(() => {
      this.displayText();
    }, 1000);
  },
  unmounted() {
    this.stopRendering();
    clearTimeout(this.typingTimeOut);
    clearTimeout(this.getTimeTimeOut);
  },
};
</script>
