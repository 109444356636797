<template>
  <div
    id="cityBar"
    ref="slideTextBox"
    class="position-lg-absolute top-0 left-0 bg-primary text-white
    px-0 py-2 p-lg-3 w-100"
    :class="sideOpen && mode !== 'desktop' ? 'open' : ''"
  >
    <!-- Desktop static city text -->
    <div class="d-none d-lg-block">
      <span class="en text-uppercase me-3">hong kong</span>
      <span class="en text-uppercase me-3">macao</span>
      <span class="en text-uppercase me-3">bejing</span>
      <span class="en text-uppercase me-3">san francisco</span>
      <span class="en text-uppercase me-3">tallinn</span>
      <span class="en text-uppercase me-3">rome</span>
    </div>
    <!-- Desktop static city text -->

    <!-- Mobile sliding text -->
    <div
      class="sliding-holder-citybar position-relative w-100 overflow-hidden
      d-flex d-lg-none align-items-center"
      :class="sideOpen ? 'open' : ''"
    >
      <span
        ref="title"
        class="en position-absolute text-uppercase mb-0"
      >
        hong kong&nbsp;&nbsp;&nbsp;&nbsp;macao&nbsp;&nbsp;&nbsp;&nbsp;
        bejing&nbsp;&nbsp;&nbsp;&nbsp;san francisco&nbsp;&nbsp;&nbsp;&nbsp;
        tallinn&nbsp;&nbsp;&nbsp;&nbsp;rome&nbsp;&nbsp;&nbsp;&nbsp;
        hong kong&nbsp;&nbsp;&nbsp;&nbsp;macao&nbsp;&nbsp;&nbsp;&nbsp;
        bejing&nbsp;&nbsp;&nbsp;&nbsp;san francisco&nbsp;&nbsp;&nbsp;&nbsp;
        tallinn&nbsp;&nbsp;&nbsp;&nbsp;rome&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      <span
        ref="title-clone"
        class="en position-absolute text-uppercase mb-0"
      >
        hong kong&nbsp;&nbsp;&nbsp;&nbsp;macao&nbsp;&nbsp;&nbsp;&nbsp;
        bejing&nbsp;&nbsp;&nbsp;&nbsp;san francisco&nbsp;&nbsp;&nbsp;&nbsp;
        tallinn&nbsp;&nbsp;&nbsp;&nbsp;rome&nbsp;&nbsp;&nbsp;&nbsp;
        hong kong&nbsp;&nbsp;&nbsp;&nbsp;macao&nbsp;&nbsp;&nbsp;&nbsp;
        bejing&nbsp;&nbsp;&nbsp;&nbsp;san francisco&nbsp;&nbsp;&nbsp;&nbsp;
        tallinn&nbsp;&nbsp;&nbsp;&nbsp;rome&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
    </div>
    <!-- Mobile sliding text -->
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  props: ['mode', 'side-open'],
  data() {
    return {
      textWidth: [],
      startPos: [
        {
          start: 0,
          target1: 0,
          target2: 0,
          cloneStart: 0,
        },
      ],
    };
  },
  watch: {
    mode() {
      if (this.mode !== '' && this.mode !== 'desktop') {
        this.initPosition();
        this.loopRender();
      }
    },
  },
  methods: {
    initPosition() {
      this.textWidth[0] = this.$refs.title.getBoundingClientRect().width;
      this.startPos[0].target1 = -this.textWidth[0];
      [this.startPos[0].target2] = this.textWidth;
      this.startPos[0].cloneStart = this.textWidth;
      this.$refs['title-clone'].style.left = this.formatPos(this.textWidth);
    },
    // start the render loop animation (rAF)
    loopRender() {
      if (!this.requestId) {
        this.requestId = requestAnimationFrame(() => {
          this.render();
        });
      }
    },
    // stop the render loop animation (rAF)
    stopRendering() {
      if (this.requestId) {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
      }
    },
    formatPos(pos) {
      return `${pos}px`;
    },
    render() {
      this.requestId = undefined;
      if (this.startPos[0].start <= this.startPos[0].target1) {
        this.startPos[0].start = this.startPos[0].target2;
      } else {
        this.startPos[0].start -= 1;
      }
      if (this.startPos[0].cloneStart <= this.startPos[0].target1) {
        this.startPos[0].cloneStart = this.startPos[0].target2;
      } else {
        this.startPos[0].cloneStart -= 1;
      }
      gsap.set(this.$refs.title, { left: this.formatPos(this.startPos[0].start) });
      gsap.set(this.$refs['title-clone'], { left: this.formatPos(this.startPos[0].cloneStart) });
      this.loopRender();
    },
  },
  mounted() {
    // console.log(this.mode);
    // if (this.mode !== 'desktop') {
    //   this.initPosition();
    //   this.loopRender();
    // }
  },
  unmounted() {
    this.stopRendering();
  },
};
</script>
